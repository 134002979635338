var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center" },
    [
      _vm._t("content", null, { isReadOnly: _vm.isReadOnly || !_vm.content }),
      !_vm.isReadOnly
        ? _c("b-icon", {
            class: "cursor-pointer ml-1",
            attrs: {
              icon: !_vm.content ? "shield-lock" : "shield-check",
              variant: "primary",
              "font-scale": "1.5",
              title: !_vm.content
                ? _vm.$t("Clique para habilitar a edição do campo")
                : _vm.$t("Campo habilitado para edição"),
            },
            on: { click: _vm.onAuthorizeAction },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }